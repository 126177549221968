import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";

HC_more(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);

export default function TreemapChart({ chartData, handlePointClick }) {
    const options = {
        plotOptions: {
            treemap: {
                colorByPoint: true,
                dataLabels: {
                    formatter: function (data) {
                        if (this.point.parent != (this.series.rootNode || null)) {
                            return this.key;
                        }
                    },
                },
            },
        },
        series: [
            {
                type: "treemap",
                layoutAlgorithm: "squarified",
                alternateStartingDirection: true,
                borderColor: "#fff",
                borderRadius: 6,
                borderWidth: 2,
                dataLabels: {
                    style: {
                        textOutline: "none",
                    },
                },
                levels: [
                    {
                        level: 1,
                        layoutAlgorithm: "sliceAndDice",
                        dataLabels: {
                            enabled: true,
                            align: "left",
                            verticalAlign: "top",
                            style: {
                                fontSize: "15px",
                                fontWeight: "bold",
                            },
                        },
                    },
                ],
                data: chartData,
                point: {
                    events: {
                        click: function (event) {
                            handlePointClick(event.point.name);
                        },
                    },
                },
            },
        ],
        title: {
            text: "Company Topics",
            align: "left",
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        tooltip: {
            useHTML: true,
            shared: false,
            formatter: function(tooltip) {
                var sentiment = 0
                if (this.point.sentiment) {
                    sentiment = this.point.sentiment.toFixed(2)
                }
                return '<div class="my-tooltip">' +
                '<h3>' + this.point.name + '</h3>' +
                '<p style="font-size: 14px"><strong>Sentiment:</strong> ' + sentiment + '</p>' +
                '<p style="font-size: 14px"><strong>Volume:</strong> ' + this.point.volume + '</p>' +
                '</div>';
            }
        },
        credits: {
            enabled: false,
        },
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { width: "98%" } }}
        />
    );
}
