import React, { useEffect, useState } from 'react';
import '../../assets/css/company-page.css';
import { useSelector, useDispatch } from 'react-redux';
import {dashboardSlice, selectCompanyDateFilter, selectFilterType, selectFilterTags} from '../../reducers/dashboardSlice';
import PieChart from '../common/PieChart';
import Chip from '@mui/material/Chip';
import { getEntityInfo } from '../../api';

export default function CompanyHeader({
    pieChartData, priceData
}) {

    const dateFilter = useSelector(selectCompanyDateFilter)
    const filterTags = useSelector(selectFilterTags)
    const tickerName = window.location.pathname.split('/')[2]
    const dispatch = useDispatch();
    const [companyName, setCompanyName] = useState('')
    const filterType = useSelector(selectFilterType)

    useEffect(() => {
        getEntityInfo(tickerName).then((res) => {
            setCompanyName(res[tickerName])
        })
    }, [tickerName]);

    const getPriceDiff = (firstPrice, lastPrice) => { return (lastPrice - firstPrice).toFixed(2); }
    const getPricePerc = (firstPrice, lastPrice) => { return (((lastPrice / firstPrice) - 1) * 100).toFixed(2); }
    const handleDelete = (tag) => {
        if (filterType === 'date') {
            dispatch(dashboardSlice.actions.setCompanyDateFilter(''))
        } else {
            dispatch(dashboardSlice.actions.deleteFilterTags(tag))
        }
    }
    return (
        <div className={`info-container`}>
            <div>
                <div className='flex items-baseline'>
                    <div className='company-name'>{tickerName}</div>
                    {priceData && priceData.length > 0 && (
                        <div className='flex mr-4 text-[16px]'>
                            <div className='mr-4'>{(priceData[priceData.length - 1].price).toFixed(2)}</div>
                            <div className={`${getPriceDiff(priceData[0].price, priceData[priceData.length - 1].price) < 0 ? 'text-red-500' : 'text-green-500'}`}>
                                {getPriceDiff(priceData[0].price, priceData[priceData.length - 1].price)} ({getPricePerc(priceData[0].price, priceData[priceData.length - 1].price)}%)</div>
                        </div>
                    )}
                    {dateFilter &&
                        <Chip sx={{marginRight: '6px'}} color="primary" onDelete={() => handleDelete('')} label={dateFilter} />
                    }
                    {/*<Stack direction="row" spacing={1}>*/}
                    {/*    {isArrayNotEmpty(filterTags) ? filterTags.map((tag) => <Chip color="primary"*/}
                    {/*                                                                 onDelete={() => handleDelete(tag)}*/}
                    {/*                                                                 label={tag} sx={{ textTransform: 'capitalize' }}/>) : null}*/}
                    {/*</Stack>*/}
                </div>
                <div>{companyName}</div>
            </div>
            <div className='pie-chart'>
                <PieChart chartData={pieChartData} />
            </div>
        </div>
    );
}
