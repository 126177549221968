import React, {useState, useEffect} from 'react';
import ProjectSidebar from "../components/classification-page/ProjectSidebar";
import ProjectPage from "../components/classification-page/ProjectPage";

const ProjectDashboard = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        const storedProjects = JSON.parse(localStorage.getItem("projects")) || [];
        setProjects(storedProjects);
        if (storedProjects && storedProjects.length > 0) {
            setSelectedProject(storedProjects[0].name)
        }
    }, []);

    const addProject = () => {
        const newProjectName = `Project ${projects.length + 1}`;  // Ensure naming remains consistent
        const newProject = {
            name: newProjectName,
            inputParagraph: '',
            classifier: 'transcripts',
            apiResponse: [],
            sentenceVersions: {},
            initialParaSentiment: {
                posCount: 0,
                negCount: 0,
                sentiment: 0.0
            }
        };
        setProjects([...projects, newProject]);
        localStorage.setItem("projects", JSON.stringify([...projects, newProject]));
        setSelectedProject(newProjectName);
    };

    const handleProjectClick = (projectName) => {
        setSelectedProject(projectName);
    };

    const updateProjectName = (oldName, newName) => {
        if (!newName.trim()) return; // Prevent duplicates
        // parse through projects and check if newName does not exist
        const sameNameProjects = projects.filter(p => p.name === newName);
        if (sameNameProjects.length !== 0) return;

        const trimmedNewName = newName.trim()
        // Find the project by oldName and update its name
        const updatedProjects = projects.map(project =>
            project.name === oldName ? {...project, name: trimmedNewName} : project
        );

        setProjects(updatedProjects);
        localStorage.setItem("projects", JSON.stringify(updatedProjects));
        setSelectedProject(trimmedNewName);
    };

    const updateProjectDetails = (name, newProject) => {
        // Update the project details
        const updatedProjects = projects.map(project =>
            project.name === name ? newProject : project
        );

        setProjects(updatedProjects);
        localStorage.setItem("projects", JSON.stringify(updatedProjects));
    };

    const selectedProjectObj = projects.filter(p => p.name === selectedProject)[0]

    return (
        <div className="flex">
            {/* Sidebar */}
            <ProjectSidebar
                projects={projects}
                addProject={addProject}
                onProjectClick={handleProjectClick}
                onRenameProject={updateProjectName}
                selectedProjectName={selectedProjectObj}
            />
            {/* Main Content */}
            {selectedProject && <ProjectPage project={selectedProjectObj}
                                             onUpdateDetails={updateProjectDetails}/>}
        </div>
    );
};

export default ProjectDashboard;
