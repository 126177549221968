import React from 'react';

const SentenceItem = ({sentence}) => {
    const getBgColor = (sentiment) => {
        return sentiment === 1 ? 'bg-green-100' :
            sentiment === 0 ? 'bg-gray-100' :
                'bg-red-100';
    }
    return (
        <div className="p-1 border rounded bg-white shadow-sm mb-4">
            <div className="flex flex-col mt-2 justify-center">
                {sentence.topicdetails.map((topic, idx) => (
                    <div key={idx} className="mb-2">
                        {topic.topic && topic.topic !== '' && (
                            <div className={`sentence-topic-name ${getBgColor(topic.sentiment)}`}>{topic.topic}</div>
                        )}
                        {/* Table-like structure for alignment */}
                        <div className="grid grid-cols-2 gap-x-4 mt-2 ml-2">
                            <div className="text-left font-medium">Sentiment</div>
                            <div className="text-left">{topic.sentiment}</div>

                            <div className="text-left font-medium">Confidence</div>
                            <div className="text-left">{topic.confidence.toFixed(2)}</div>

                            <div className="text-left font-medium">Positive</div>
                            <div className="text-left">{topic.probabilities[0].toFixed(2)}</div>

                            <div className="text-left font-medium">Neutral</div>
                            <div className="text-left">{topic.probabilities[1].toFixed(2)}</div>

                            <div className="text-left font-medium">Negative</div>
                            <div className="text-left">{topic.probabilities[2].toFixed(2)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default SentenceItem;
