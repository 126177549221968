import React from "react";
import '../../assets/css/classification.css';

// Converts degrees to radians
const degToRad = (deg) => (deg * Math.PI) / 180;

// Function to calculate arc paths with gradient stroke
const calculateArc = (size, startDeg, endDeg, gradientId, strokeWidth) => {
    const radius = size * 0.45; // Radius for colored arc
    const centerX = size / 1.5;
    const centerY = size * 0.55; // Shift down to fit ticks

    const startAngle = degToRad(startDeg);
    const endAngle = degToRad(endDeg);

    const startX = centerX + radius * Math.cos(startAngle);
    const startY = centerY - radius * Math.sin(startAngle);
    const endX = centerX + radius * Math.cos(endAngle);
    const endY = centerY - radius * Math.sin(endAngle);

    const largeArcFlag = endDeg - startDeg <= 180 ? 0 : 1;

    return (
        <path
            d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
            stroke={`url(#${gradientId})`}
            strokeWidth={strokeWidth}
            fill="none"
            strokeLinecap="butt" // No rounded edges
        />
    );
};

// Function to create speedometer-like tick marks outside the colored arc
const generateOuterTicks = (size, startDeg, endDeg, tickCount = 30) => {
    const radius = size * 0.60; // **Outside the colored arc**
    const centerX = size / 1.5;
    const centerY = size * 0.55; // Adjusted to fit ticks
    const ticks = [];

    for (let i = 0; i <= tickCount; i++) {
        const angle = degToRad(startDeg + (i / tickCount) * (endDeg - startDeg));

        const innerRadius = radius; // Outer edge of the arc
        const outerRadius = i % 2 === 0 ? radius + size * 0.04 : radius + size * 0.02; // Alternating tick sizes

        const x1 = centerX + innerRadius * Math.cos(angle);
        const y1 = centerY - innerRadius * Math.sin(angle);
        const x2 = centerX + outerRadius * Math.cos(angle);
        const y2 = centerY - outerRadius * Math.sin(angle);

        ticks.push(
            <line
                key={i}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke="#d3d3d3"
                strokeWidth={size * 0.008} // 2px equivalent
            />
        );
    }

    return ticks;
};

const Gauge = ({heading, paraSentiment, size = 200}) => {
    if (!paraSentiment) return;
    // if(!paraSentiment.sentiment) return;
    const totalCount = paraSentiment.posCount + paraSentiment.negCount || 1; // Avoid division by zero

    // The arc spans from 170° to 10° (200° total)
    const startDeg = 160;
    const endDeg = 20;

    // Compute angles based on proportions
    const posAngle = startDeg + (paraSentiment.posCount / totalCount) * (endDeg - startDeg);
    const negAngle = posAngle; // Start where positive arc ends

    // Define stroke widths
    const highlightStrokeWidth = size * 0.25; // **Thicker colored arc (30px)**

    return (
        <div
            className="p-2 cursor-pointer flex flex-col items-center rounded-md border border-gray-300 hover:bg-opacity-80 mb-2 sentence-border">
            <div className="relative flex justify-center items-center mb-1" style={{width: size, height: size * 0.4}}>
                <svg className="absolute w-full h-full" viewBox={`0 0 ${size * 1.3} ${size * 0.4}`}>
                    {/* Gradient Definitions */}
                    <defs>
                        {/* Positive Arc Gradient (Green) */}
                        <linearGradient id="gradientPos" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#3DB896"/>
                            <stop offset="100%" stopColor="#6AD6B9"/>
                        </linearGradient>

                        {/* Negative Arc Gradient (Red) */}
                        <linearGradient id="gradientNeg" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#F89293"/>
                            <stop offset="100%" stopColor="#EA5657"/>
                        </linearGradient>
                    </defs>

                    {/* Outer Speedometer Ticks (Above the colored arc) */}
                    {generateOuterTicks(size, startDeg, endDeg)}

                    {/* Positive Arc (Gradient Green) */}
                    {calculateArc(size, startDeg, posAngle, "gradientPos", highlightStrokeWidth)}

                    {/* Negative Arc (Gradient Red) */}
                    {calculateArc(size, posAngle, endDeg, "gradientNeg", highlightStrokeWidth)}

                </svg>
            </div>
            {/* Header */}
            <div
                className="flex items-center justify-center text-[16px] font-semibold bg-white border-2 border-gray-400 rounded-full"
            >{heading}
            </div>
            {/* Sentiment in the center */}
            <div
                className="flex items-center justify-center text-[14px] text-gray-400 whitespace-nowrap"
                style={{
                    width: size * 0.2
                }}
            >
               {paraSentiment.sentiment.toFixed(4)} ( {paraSentiment.posCount} pos, {paraSentiment.negCount} neg)
            </div>
        </div>
    );
};

export default Gauge;
