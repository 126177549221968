import React, {useState, useRef} from 'react';
import CompanyCharts from '../components/company-page/CompanyCharts';
import ContentTable from '../components/company-page/ContentTable';

const CompanyPage = () => {
    const [leftPaneWidth, setLeftPaneWidth] = useState(window.innerWidth * 0.35);
    const containerRef = useRef(null);
    const splitLineRef = useRef(null);

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const containerWidth = containerRef.current.offsetWidth;
        const newLeftPaneWidth = e.clientX - containerRef.current.offsetLeft;
        setLeftPaneWidth(Math.min(Math.max(newLeftPaneWidth, 100), containerWidth - 100));
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <div ref={containerRef} style={{display: 'flex', height: `${window.innerHeight - 140}px`}}>
            <div style={{width: leftPaneWidth, paddingLeft: '12px'}}>
                <CompanyCharts/>
            </div>
            <div
                ref={splitLineRef}
                style={{
                    width: '1px',
                    height: `${window.innerHeight - 120}px`,
                    backgroundColor: 'gray',
                    cursor: 'col-resize',
                }}
                onMouseDown={handleMouseDown}
            />
            <div style={{flex: 1, height: '100%', paddingRight: '8px'}}>
                <ContentTable/>
            </div>
        </div>
    );
};

export default CompanyPage;