import React, {useEffect, useRef, useState} from 'react';
import {Check, MoreVert, Edit, Delete, CheckCircle} from '@mui/icons-material';
import '../../assets/css/classification.css';
import {classification_api} from "../../api";

const SentenceList = ({
                          sentences,
                          onSentenceClick,
                          selectedSentenceIndex,
                          selectedParentIndex,
                          sentenceVersions,
                          setSentenceVersions,
                          onDeleteSentence,
                          classifier,
                          handleSentenceVersionSwap
                      }) => {
    const sentenceListRef = useRef(null);
    const [editingSentenceIndex, setEditingSentenceIndex] = useState(-1);
    const [newVersionText, setNewVersionText] = useState("");
    const [menuPosition, setMenuPosition] = useState({top: 0, left: 0});
    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);
    const menuButtonRef = useRef(null);

    const handleMenuOpen = (event) => {
        const buttonElement = event.currentTarget;
        const rect = buttonElement.getBoundingClientRect();
        const menuHeight = 120; // Approximate height of the menu
        const spaceBelow = window.innerHeight - rect.bottom;

        // Determine whether to position the menu below or above the button
        const topPosition = spaceBelow >= menuHeight
            ? rect.bottom + window.scrollY // Show below button if space allows
            : rect.top + window.scrollY - menuHeight; // Show above button if no space below

        setMenuPosition({
            top: topPosition,
            left: rect.left + window.scrollX
        });
        setMenuVisible(true);
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                menuButtonRef.current &&
                !menuButtonRef.current.contains(event.target)
            ) {
                setMenuVisible(false);
            }
        };

        if (menuVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuVisible]);

    const handleMenuClose = () => {
        setMenuVisible(false);
    };

    const handleEdit = () => {
        setEditingSentenceIndex(selectedSentenceIndex)
        handleMenuClose();
    };

    const getVersionResponse = async (sentenceIndex, newVersionIndex) => {
        const versionSentimentInfo = await classification_api(newVersionText, classifier);
        const totalSentiment = versionSentimentInfo.sentences
            .filter(s => s.index === 0)
            .reduce((acc, curr) => acc + curr.sentiment, 0);
        const sentenceSentiment = totalSentiment > 0 ? 1 : (totalSentiment < 0) ? -1 : 0;
        const topicDetails = versionSentimentInfo.sentences
            .filter(s => s.index === 0)
            .map(topic => ({
                topic: topic.topic,
                sentiment: topic.sentiment,
                confidence: topic.confidence,
                probabilities: topic.probabilities
            }));
        return {
            index: newVersionIndex,
            text: versionSentimentInfo.sentence_text.filter(s => s.index === 0)[0].content,
            sentence_sentiment: sentenceSentiment,
            topicdetails: topicDetails,
            parentIndex: sentenceIndex
        }
    }

    const addNewVersion = async (sentenceIndex) => {
        if (!newVersionText.trim()) return;
        let newVersionIndex = 1
        if (sentenceVersions[sentenceIndex]?.length > 0) {
            newVersionIndex = sentenceVersions[sentenceIndex]?.[sentenceVersions[sentenceIndex].length - 1].index + 1;
        }
        const versionObj = await getVersionResponse(sentenceIndex, newVersionIndex);
        setSentenceVersions((prevVersions) => {
            const updatedVersions = [...(prevVersions[sentenceIndex] || []), versionObj];
            return {...prevVersions, [sentenceIndex]: updatedVersions};
        });

        setNewVersionText(""); // Clear input field after adding
    };

    const handleDelete = () => {
        if (selectedParentIndex === -1) {
            console.log('trying to delete a parent sentence.')
            onDeleteSentence(selectedSentenceIndex)
            setSentenceVersions((prevVersions) => {
                const updatedVersions = {...prevVersions};
                if (updatedVersions[selectedSentenceIndex]) {
                    delete updatedVersions[selectedSentenceIndex];
                }
                return updatedVersions;
            });
        } else {
            console.log('trying to delete a version sentence.')
            setSentenceVersions((prevVersions) => {
                const updatedVersions = {...prevVersions};
                console.log(updatedVersions)
                Object.keys(updatedVersions).forEach((key) => {
                    updatedVersions[key] = updatedVersions[key].filter((version) => version.index !== selectedSentenceIndex);
                });
                return updatedVersions;
            });
        }
        handleMenuClose();
    };

    const handleActivate = () => {
        handleSentenceVersionSwap()
        handleMenuClose();
    };

    const SentenceContainer = ({sentence}) => {
        const isParent = () => {
            const allVersions = sentenceVersions[sentence.index] || [];
            return sentence.parentIndex === -1 && allVersions.length > 0;
        };
        const isVersion = sentence.parentIndex !== -1;
        const isLastVersion = () => {
            const allVersions = sentenceVersions[sentence.parentIndex] || [];
            if (allVersions.length === 0) return false;
            const lastVersion = allVersions[allVersions.length - 1];
            return lastVersion?.index === sentence.index && sentence.parentIndex !== -1;
        };
        const sentenceNumber = isVersion ? (sentence.parentIndex + '.' + sentence.index) : sentence.index;
        let bgColor = sentence.sentence_sentiment === 1 ? 'bg-green-100' :
            sentence.sentence_sentiment === 0 ? 'bg-white' :
                'bg-red-100';
        return (
            <div onClick={() => onSentenceClick(sentence.index, sentence.parentIndex)}
                 className={`flex w-full items-start p-1 justify-between hover:bg-opacity-80 ${bgColor} sentence-container rounded`}
            >
                {/* Sentence Number */}
                <div className={`sentence-number ${isParent() ? 'has-versions' : ''}`}>{sentenceNumber}</div>
                {/* After Line */}
                {(isParent() && editingSentenceIndex === sentence.index) || (isVersion && !isLastVersion()) ?
                    <div className='after-line'></div> : null}
                {/* Sentence Text */}
                <div className="flex-1 cursor-text">{sentence.text}</div>
                {/* Menu Button */}
                <button ref={menuButtonRef} onClick={(e) => handleMenuOpen(e, sentence.index, sentence.parentIndex)}
                        className="p-0 menu-button">
                    <MoreVert fontSize="small"/>
                </button>
            </div>
        );
    };

    const CustomMenu = ({menuPosition, menuVisible}) => {
        if (!menuVisible || selectedSentenceIndex === null) return null;
        return (
            <div
                ref={menuRef}
                className="custom-menu"
                style={{
                    top: `${menuPosition.top}px`,
                    left: `${menuPosition.left}px`,
                    position: 'absolute',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    minWidth: '160px',
                }}
            >
                {(selectedParentIndex === -1) && <div className="menu-item" onClick={() => handleEdit()}>
                    <Edit className="menu-icon text-blue-500"/>
                    <span>Edit</span>
                </div>}
                <div className="menu-item" onClick={() => handleDelete()}>
                    <Delete className="menu-icon text-red-500"/>
                    <span>Delete</span>
                </div>
                {(selectedParentIndex !== -1) &&
                    <div className="menu-item"
                         onClick={() => handleActivate()}>
                        <CheckCircle className="menu-icon text-green-500"/>
                        <span>Activate</span>
                    </div>}
            </div>
        );
    };

    return (
        <div ref={sentenceListRef} className="h-[calc(100vh-100px)] overflow-y-auto">
            {sentences.map((sentence) => {
                return (
                    <div key={sentence.index} className={`cursor-pointer flex flex-col items-center justify-between
                     rounded-md border border-gray-300 hover:bg-opacity-80 mb-1 ${
                        (((selectedSentenceIndex === sentence.index) && (selectedParentIndex === -1)) || (selectedParentIndex === sentence.index))
                            ? 'selected-sentence-border' : 'new-sentence-border'
                    }`}>
                        <SentenceContainer sentence={sentence}/>

                        {/* Sentence Versions Editor */}
                        {editingSentenceIndex === sentence.index && (
                            <div className="bg-white w-full rounded">
                                {/* List Versions */}
                                {sentenceVersions[sentence.index]?.map((version) => (
                                    <div key={version.index} className="sentence-version">
                                        <SentenceContainer sentence={version}/>
                                    </div>
                                ))}

                                {/* Input for New Version */}
                                <div className="flex items-center gap-2 p-2">
                                    <input
                                        type="text"
                                        value={newVersionText}
                                        onChange={(e) => setNewVersionText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                addNewVersion(sentence.index).then(r => console.log('added new version by enter.'));
                                            }
                                        }}
                                        className="bg-gray-200 text-gray-700 rounded-lg p-2 flex-1 focus:outline-none no-border"
                                        placeholder="Enter new version..."
                                    />
                                    <button
                                        onClick={() => addNewVersion(sentence.index)}
                                        className="p-1 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                    >
                                        <Check fontSize="small"/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            {/* Custom Menu */}
            <CustomMenu
                menuPosition={menuPosition}
                menuVisible={menuVisible}
                handleMenuClose={handleMenuClose}
            />
        </div>
    );
};

export default SentenceList;

// Scroll to the selected sentence when it changes and update menu position accordingly
// useEffect(() => {
//     if (sentenceListRef.current && selectedSentenceIndex !== null) {
//         const selectedSentence = sentenceListRef.current.children[selectedSentenceIndex];
//         if (selectedSentence) {
//             selectedSentence.scrollIntoView({behavior: 'smooth', block: 'start'});
//
//             // Wait for scroll to complete before updating menu position
//             setTimeout(() => {
//                 const buttonElement = selectedSentence.querySelector('.menu-button'); // Ensure your menu button has this class
//                 if (buttonElement) {
//                     const rect = buttonElement.getBoundingClientRect();
//                     const menuHeight = 120; // Approximate menu height
//                     const spaceBelow = window.innerHeight - rect.bottom;
//
//                     const topPosition = spaceBelow >= menuHeight
//                         ? rect.bottom + window.scrollY
//                         : rect.top + window.scrollY - menuHeight;
//
//                     setMenuPosition({
//                         top: topPosition,
//                         left: rect.left + window.scrollX
//                     });
//                 }
//             }, 300); // Delay to allow smooth scroll completion
//         }
//     }
// }, [selectedSentenceIndex]);
