import React, { useState } from 'react';
import Logo from '../../assets/images/Alexandria_Logo.png'
import { Edit } from '@mui/icons-material'

const ProjectSidebar = ({ projects, addProject, onProjectClick, onRenameProject, selectedProjectName }) => {
    const [editingProject, setEditingProject] = useState(null);
    const [newName, setNewName] = useState("");

    const handleRename = (e, currName) => {
        e.preventDefault();
        if (newName.trim() && newName !== currName) {
            onRenameProject(currName, newName);
        }
        setEditingProject(null);
    };

    return (
        <div className="w-1/5 max-w-[180px] h-screen bg-gray-900 text-white flex flex-col p-4 select-none">
            {/* Logo */}
            <div className="flex justify-center mb-4">
                <img src={Logo} alt="Alexandria Logo" className="w-24 h-auto" />
            </div>
            {/* Header */}
            <div className="flex items-center justify-between mb-4">
                <div className="text-sm font-bold">PROJECTS</div>
                <button
                    className="p-1 rounded bg-gray-700 hover:bg-gray-600 flex items-center justify-center w-6 h-6"
                    onClick={addProject}
                >
                    <span className="text-xl font-bold">+</span>
                </button>
            </div>

            {/* Project List */}
            <div className="flex flex-col gap-2">
                {projects.map((project, idx) => (
                    <div key={idx} className={`group p-1 bg-gray-800 rounded flex items-center justify-between ${project.name === selectedProjectName?.name ? 'selected-proj-border' : ''}`}>
                        {editingProject === project.name ? (
                            <form onSubmit={(e) => handleRename(e, project.name)} className="flex w-full">
                                <input
                                    type="text"
                                    className="p-1 bg-gray-700 rounded text-white w-full outline-none"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    autoFocus
                                    onBlur={() => setEditingProject(null)}
                                />
                            </form>
                        ) : (
                            <div
                                className="cursor-pointer p-1 flex-1"
                                onClick={() => onProjectClick(project.name)}
                            >
                                {project.name}
                            </div>
                        )}
                        <button
                            className="p-1 rounded bg-gray-700 hover:bg-gray-600 flex items-center justify-center w-6 h-6 opacity-0 group-hover:opacity-100 transition"
                            onClick={() => { setEditingProject(project.name); setNewName(project.name); }}
                        >
                            <Edit style={{fontSize: '16px'}}/>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectSidebar;
