import React, {useEffect, useState, useRef} from 'react';
import {classification_api} from "../../api";
import SentenceList from "./SentenceList";
import SentenceItem from "./SentenceItem";
import CircularProgress from '@mui/material/CircularProgress';
import {Delete, East, SaveOutlined, Save} from '@mui/icons-material';
import Gauge from "./SentimentGauge";
import TreemapChart from "../classification-page/TreemapChart";
import {getColorHardcoded} from "../../utils";
import '../../assets/css/classification.css';


const ProjectPage = ({project, onUpdateDetails}) => {

    const [inputParagraph, setInputParagraph] = useState(project.inputParagraph || "");
    const [classifier, setClassifier] = useState(project.classifier || "transcripts");
    const [apiResponse, setApiResponse] = useState(project.apiResponse || []);
    const [selectedSentenceIndex, setSelectedSentenceIndex] = useState(0);
    const [selectedParentIndex, setSelectedParentIndex] = useState(-1);
    const [sentenceVersions, setSentenceVersions] = useState(project.sentenceVersions || {}); // Store versions for each sentence
    const [loading, setLoading] = useState(false);  // Track loading state
    const [error, setError] = useState(null);  // Store error message
    const [initialParaSentiment, setInitialParaSentiment] = useState(project.initialParaSentiment || {
        posCount: 0,
        negCount: 0,
        sentiment: 0.0
    })
    const [treemapData, setTreemapData] = useState({});
    const gaugeContainerRef = useRef(null);
    const [gaugeWidth, setGaugeWidth] = useState(200);
    const [projectName, setProjectName] = useState(project.name || 'Project Default Name')

    const isFirstRender = useRef(true);
    useEffect(() => {
        if(projectName !== project.name) {
            setProjectName(project.name)
            setInputParagraph(project.inputParagraph)
            setClassifier(project.classifier)
            setApiResponse(project.apiResponse)
            setSentenceVersions(project.sentenceVersions)
            setInitialParaSentiment(project.initialParaSentiment)
        }
    }, [project])

    useEffect(() => {
        const chartData = generateChartData(apiResponse);
        setTreemapData(chartData)
    }, [apiResponse]);

    useEffect(() => {
        if (gaugeContainerRef.current) {
            setGaugeWidth(Math.floor(gaugeContainerRef.current.offsetWidth / 2))
        }
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // Skip first update
            return;
        }

        // Avoid unnecessary updates by checking if values have actually changed
        if (
            project.inputParagraph === inputParagraph &&
            project.classifier === classifier &&
            project.apiResponse === apiResponse &&
            project.sentenceVersions === sentenceVersions &&
            project.initialParaSentiment === initialParaSentiment
        ) {
            return;
        }

        onUpdateDetails(project.name, {
            name: project.name,
            inputParagraph,
            classifier,
            apiResponse,
            sentenceVersions,
            initialParaSentiment
        });
    }, [inputParagraph, classifier, apiResponse, sentenceVersions, initialParaSentiment]);

    const handleParagraphChange = (e) => {
        setInputParagraph(e.target.value);
    };

    const handleClassifierChange = (e) => {
        setClassifier(e.target.value);
    };

    const handleClassify = async () => {
        if (!inputParagraph.trim()) return; // Avoid empty submissions

        setLoading(true);  // Start loader
        setError(null);  // Reset error message
        try {
            const sentence_info = await classification_api(inputParagraph, classifier);

            // Map the API response to the new structure
            const mappedResponse = sentence_info.sentence_text.map((sentence, index) => {
                // Calculate average sentiment for the sentence
                const totalSentiment = sentence_info.sentences
                    .filter(s => s.index === index)
                    .reduce((acc, curr) => acc + curr.sentiment, 0);
                const sentenceSentiment = totalSentiment / sentence_info.sentences.filter(s => s.index === index).length;

                // Create topic details for each sentence
                const topicDetails = sentence_info.sentences
                    .filter(s => s.index === index)
                    .map(topic => ({
                        topic: topic.topic,
                        sentiment: topic.sentiment,
                        confidence: topic.confidence,
                        probabilities: topic.probabilities
                    }));

                return {
                    text: sentence.content,
                    sentence_sentiment: sentenceSentiment,
                    topicdetails: topicDetails,
                    parentIndex: -1
                };
            });
            let totalResponse = [...apiResponse, ...mappedResponse]
            totalResponse = totalResponse.map((s, i) => ({...s, index: i}));
            setApiResponse(totalResponse)
            setInitialParaSentiment(calculateParagraphSentiment(totalResponse))
        } catch (error) {
            console.error("Error classifying text:", error);
            setApiResponse("Error processing the classification.");
            setError(error.message);
        } finally {
            setLoading(false);  // Stop loader
        }
    };

    const handleSentenceClick = (index, parentIndex) => {
        setSelectedSentenceIndex(index);
        setSelectedParentIndex(parentIndex)
    };

    const handleDeleteSentence = (index) => {
        setApiResponse((prevSentences) => prevSentences.filter(s => s.index !== index));
    };

    const getSelectedSentence = () => {
        if (apiResponse) {
            if (selectedParentIndex === -1) {
                return apiResponse.filter(s => s.index === selectedSentenceIndex)[0]
            } else {
                return sentenceVersions[selectedParentIndex].filter(s => s.index === selectedSentenceIndex)[0]
            }
        } else return null;
    }

    const handleSentenceVersionSwap = () => {
        if (selectedParentIndex === -1) return; // Should not activate a parent sentence

        setApiResponse((prevSentences) => {
            const originalSentence = prevSentences.find(s => s.index === selectedParentIndex);
            const versionList = sentenceVersions[selectedParentIndex] || [];
            const versionSentence = versionList.find(v => v.index === selectedSentenceIndex);

            if (!originalSentence || !versionSentence) return prevSentences; // Ensure valid swap

            // Replace the original sentence with the selected version
            return prevSentences.map(s =>
                s.index === selectedParentIndex ? {
                    index: selectedParentIndex,
                    text: versionSentence.text,
                    sentence_sentiment: versionSentence.sentence_sentiment,
                    topicdetails: versionSentence.topicdetails,
                    parentIndex: -1
                } : s
            );
        });

        setSentenceVersions((prevVersions) => {
            const versionList = prevVersions[selectedParentIndex] || [];
            if (versionList.length === 0) return prevVersions;
            const originalSentence = apiResponse.find(s => s.index === selectedParentIndex);

            // Move the original sentence to the beginning of the versions list
            const updatedVersions = [
                {
                    index: selectedSentenceIndex,
                    text: originalSentence.text,
                    sentence_sentiment: originalSentence.sentence_sentiment,
                    topicdetails: originalSentence.topicdetails,
                    parentIndex: selectedParentIndex
                }, // Original sentence
                ...versionList.filter(v => v.index !== selectedSentenceIndex) // Other versions
            ];

            return {
                ...prevVersions,
                [selectedParentIndex]: updatedVersions
            };
        });


    };

    const selectedSentence = getSelectedSentence();

    const deleteAllSentences = () => {
        setApiResponse([]);
        setSentenceVersions({});
    };

    const calculateParagraphSentiment = (sentences) => {
        if (!sentences || sentences.length === 0) return {
            posCount: 0,
            negCount: 0,
            sentiment: 0.0
        };  // Return neutral sentiment if no sentences

        const {positive, negative} = sentences.reduce(
            (acc, {sentence_sentiment}) => {
                if (sentence_sentiment > 0) acc.positive++;
                else if (sentence_sentiment < 0) acc.negative++;
                return acc;
            },
            {positive: 0, negative: 0}
        );

        // Calculate and return the final sentiment score
        const resultSentiment = Math.log((positive + 1) / (negative + 1)) / Math.log(10);
        return {
            posCount: positive,
            negCount: negative,
            sentiment: parseFloat(resultSentiment.toFixed(7))
        }
    };

    function generateChartData(apiResponse) {
        if (!apiResponse) return [];

        const topicMap = {};

        // First loop: Collect sentiment data
        apiResponse.forEach(({ topicdetails }) => {
            if (!Array.isArray(topicdetails)) return; // Safety check

            topicdetails.forEach(({ topic, sentiment }) => {
                if (!topic || topic.trim() === '') return;

                if (!topicMap[topic]) {
                    topicMap[topic] = { sentimentList: [] };
                }
                topicMap[topic].sentimentList.push(sentiment);
            });
        });

        // Second loop: Process and structure topic data
        Object.entries(topicMap).forEach(([topic, { sentimentList }]) => {
            const sentimentCount = sentimentList.length;
            const avgSentiment = sentimentCount > 0
                ? sentimentList.reduce((sum, val) => sum + val, 0) / sentimentCount
                : 0;

            const topicSentiment = Math.sign(avgSentiment);

            topicMap[topic] = {
                id: topic,
                name: topic,
                value: sentimentCount,
                volume: sentimentCount,
                color: getColorHardcoded(topicSentiment),
                sentiment: topicSentiment,
                parent: topicSentiment > 0 ? "positive"
                    : topicSentiment < 0 ? "negative"
                        : "neutral"
            };
        });

        return [
            { id: "positive", name: "positive" },
            { id: "negative", name: "negative" },
            { id: "neutral", name: "neutral" },
            ...Object.values(topicMap)
        ];
    }


    const currentParaSentiment = calculateParagraphSentiment(apiResponse)


    return (
        <div className="flex w-full pt-4">
            {/* Left Column: Classifier, Input, and Button */}
            <div className="w-1/3 pr-2 pl-4 left-container-border-r">
                <div className="flex ">
                    <h2 className="text-xl font-bold mb-4 mr-4 ">{projectName}</h2>
                </div>

                {/* Classifier Dropdown */}
                <div className="mb-2">
                    <select
                        value={classifier}
                        onChange={handleClassifierChange}
                        className="p-2 border rounded w-full bg-white"
                    >
                        <option value="transcripts-MD">Calls MD</option>
                        <option value="transcripts-QA">Calls QA</option>
                        <option value="news">News</option>
                    </select>
                </div>

                {/* Large Expandable Text Box */}
                <div className="mb-2">
                    <textarea
                        className="w-full min-h-[300px] p-3 border rounded bg-white resize-y"
                        placeholder="Enter text to classify..."
                        value={inputParagraph}
                        onChange={handleParagraphChange}
                    />
                </div>

                {/* Classify Button */}
                <button
                    onClick={handleClassify}
                    className="mb-2 w-full h-10 px-4 font-bold py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition flex items-center justify-center"
                    disabled={loading}  // Disable button while loading
                >
                    {loading ? (
                        <CircularProgress size={18} color="inherit"/>// Show loader
                    ) : error ? (
                        <span>{`Error: ${error}`}</span>  // Show error message
                    ) : (
                        <>
                            <span>Submit</span>
                            <East className="ml-2" style={{fontSize: 18}}/> {/* Right arrow with margin to the left */}
                        </>
                    )}
                </button>

                {/* Sentiment chart */}
                <div className="flex justify-between" ref={gaugeContainerRef}>
                    {(initialParaSentiment && initialParaSentiment !== {}) && <Gauge heading={'Initial Sentiment'}
                                                                                     paraSentiment={initialParaSentiment}
                                                                                     size={gaugeWidth - 20}
                    />}
                    {(currentParaSentiment && currentParaSentiment !== {}) && <Gauge heading={'Edited Sentiment'}
                                                                                     paraSentiment={currentParaSentiment}
                                                                                     size={gaugeWidth - 20}/>}
                </div>

                {(treemapData && treemapData.length > 3) &&
                    <div
                        className="h-[300px] p-2 cursor-pointer flex flex-col items-center rounded-md border border-gray-300 hover:bg-opacity-80 mb-2 sentence-border"
                        style={{overflow: "hidden", position: "relative"}}
                    >
                        <TreemapChart chartData={treemapData}/>
                    </div>
                }

            </div>

            {/* Center Column: Sentence List */}
            <div className="w-1/2 pr-4 pl-2">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm font-semibold">
                        <span>SENTENCE LIST </span>
                        <span className="text-sm text-gray-400 font-medium">({apiResponse.length} sentences)</span>
                    </div>
                    {/* Delete All Button */}
                    <div onClick={deleteAllSentences} className="delete-all-btn">
                        <Delete className="delete-all-icon text-red-400"/>
                        <span>Delete All</span>
                    </div>
                </div>
                {apiResponse && (
                    <SentenceList
                        sentences={apiResponse}
                        onSentenceClick={handleSentenceClick}
                        selectedSentenceIndex={selectedSentenceIndex}
                        selectedParentIndex={selectedParentIndex}
                        sentenceVersions={sentenceVersions}
                        setSentenceVersions={setSentenceVersions}
                        onDeleteSentence={handleDeleteSentence}
                        classifier={classifier}
                        handleSentenceVersionSwap={handleSentenceVersionSwap}
                    />
                )}
            </div>

            {/* Right Column: Sentence Details */}
            <div className="w-1/6 p-4 bg-gray-300 overflow-y-auto">
                {/*<h3 className="text-lg font-semibold mb-3">Sentence Details</h3>*/}
                {selectedSentence ? (
                    <SentenceItem sentence={selectedSentence}/>
                ) : (
                    <div>No sentence selected</div>
                )}
            </div>
        </div>
    );
};

export default ProjectPage;
