import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../../../assets/css/accounts-page.css';
import {sendMasterArchiveApi} from '../../../aePortalApi';
import {countryList, regionList} from "../../../constants";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import {getTodayDate} from "../../../utils";
import {styled} from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomSingleAutoComplete from "../CustomSingleAutoComplete";
import CustomTextField from "../CustomTextField";
import CustomSingleAutocomplete from "../CustomSingleAutoComplete";
import {useEffect} from "react";

const CreateFileForm = ({accountId, setRefreshCounter}) => {
    const [entitlement, setEntitlement] = React.useState("equity");
    const [checkedSource, setCheckedSource] = React.useState(new Set());
    const [countries, setCountries] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [countryInputValue, setCountryInputValue] = React.useState('');
    const [disableMasterBtn, setDisableMasterBtn] = React.useState(false);
    const [apiResponseText, setApiResponseText] = React.useState("")
    const [responseStatus, setResponseStatus] = React.useState(0)
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState(getTodayDate());
    const [sendEmailFlag, setSendEmailFlag] = React.useState(true);
    const [showApiResponse, setShowApiResponse] = React.useState(false);
    const [removeFLS, setRemoveFLS] = React.useState(false);

    useEffect(() => {
        setCountries([])
        setRegions([])
        setCheckedSource(new Set())
    }, [entitlement]);

    const sourceHandleChange = (source) => {
        let newCheckedSource = new Set(checkedSource);
        if (checkedSource.has(source)) {
            newCheckedSource.delete(source);
        } else {
            newCheckedSource.add(source);
        }
        setCheckedSource(new Set(newCheckedSource));
    };

    const sendMasterArchive = async () => {
        setDisableMasterBtn(true)
        // if start date and end date is not of the format yyyy-mm-dd
        if (!startDate.match(/^\d{4}-\d{2}-\d{2}$/) || !endDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // check if start date is a valid date
            setResponseStatus(-1)
            setApiResponseText("ERROR : Start and End date should be of the format yyyy-mm-dd")
            setDisableMasterBtn(false)
            return
        }
        // difference between start and end date should be more than 7 days
        if (new Date(endDate) - new Date(startDate) < 691200000) {
            console.log('difference : ', new Date(endDate) - new Date(startDate))
            setResponseStatus(-1)
            setApiResponseText("ERROR : Difference between start and end date should be more than 7 days")
            setDisableMasterBtn(false)
            return
        }
        const request = {
            "account_id": accountId,
            "entitlement": entitlement,
            "sources": entitlement !== 'earnings_transcript' ? Array.from(checkedSource) : [],
            "countries": countries,
            "regions": regions.length > 0 ? [regions] : [],
            "start_date": startDate,
            "end_date": endDate,
            "send_email": sendEmailFlag,
            "remove_fls": removeFLS
        }
        const response = await sendMasterArchiveApi(request);
        if (response['status'] === 1) {
            setResponseStatus(1);
            setApiResponseText("Generating Archive : " + response['response']['file']['status']);
        } else {
            setResponseStatus(-1);
            // ... existing error handling
        }
        setRefreshCounter(prev => prev + 1);
        setDisableMasterBtn(false);
        setShowApiResponse(true);

        // Hide the API response after 5 seconds
        setTimeout(() => {
            setShowApiResponse(false);
        }, 5000);
    }

    return (
        <Box sx={{
            padding: '20px',
            borderRadius: '8px',
            marginBottom: '20px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        }}>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
                <CustomSingleAutoComplete value={entitlement} label={'Entitlement*'} width={290}
                                          onChange={(event, newValue) => setEntitlement(newValue)}
                                          options={['equity', 'commodity_v5', 'economic_index', 'earnings_transcript', 'ftri']}/>
                <CustomTextField width={150} value={startDate} onChange={(e) => setStartDate(e.target.value)}
                                 label="Start Date*" helperText="YYYY-MM-DD"/>
                <CustomTextField width={150} value={endDate} onChange={(e) => setEndDate(e.target.value)}
                                 label="End Date*" helperText="YYYY-MM-DD"/>
            </Box>
            {entitlement !== 'earnings_transcript' && entitlement !== 'ftri' && (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginTop: '16px', marginLeft: '5px'}}>
                    {['DN', 'BNZ', 'MDT', 'PR'].map((source, index) => (<FormControlLabel
                        key={index}
                        control={<Checkbox
                            checked={checkedSource.has(source)}
                            onChange={() => sourceHandleChange(source)}
                            sx={{
                                color: '#6a5acd', '&.Mui-checked': {
                                    color: '#6a5acd',
                                }, padding: '4px'
                            }}
                        />}
                        label={source}
                    />))}
                </Box>)}
            {(entitlement === 'equity' || entitlement === 'earnings_transcript') && (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginTop: '16px'}}>
                    <CustomSingleAutocomplete multiple options={countryList} inputValue={countryInputValue}
                                              onInputChange={(event, newInputValue) => setCountryInputValue(newInputValue)}
                                              onChange={(event, newValue) => setCountries(newValue)} label='Countries'
                                              value={countries} width={300}/>
                    <CustomSingleAutoComplete label="Regions" options={regionList} width={300} value={regions}
                                              onChange={(event, newValue) => setRegions(newValue)}/>
                </Box>)}
            <div className="flex place-content-between">
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '16px', marginLeft: '5px'}}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={sendEmailFlag}
                            onChange={() => setSendEmailFlag(prev => !prev)}
                            sx={{
                                color: '#6a5acd', '&.Mui-checked': {
                                    color: '#6a5acd',
                                }, padding: '4px'
                            }}
                        />}
                        label="Send email"
                    />
                </Box>
                {entitlement === 'earnings_transcript' && <Box sx={{display: 'flex', alignItems: 'center', marginTop: '16px', marginLeft: '5px'}}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={removeFLS}
                            onChange={() => setRemoveFLS(prev => !prev)}
                            sx={{
                                color: '#6a5acd', '&.Mui-checked': {
                                    color: '#6a5acd',
                                }, padding: '4px'
                            }}
                        />}
                        label="Remove FLS"
                    />
                </Box>}
                <Button
                    variant="contained"
                    onClick={sendMasterArchive}
                    disabled={disableMasterBtn}
                    startIcon={<AddCircleOutlineIcon/>}
                    sx={{
                        marginTop: '16px', backgroundColor: '#6a5acd', '&:hover': {
                            backgroundColor: '#5a4abf',
                        },
                    }}
                >
                    Add New File
                </Button>
            </div>
            {showApiResponse && apiResponseText && (
                <Box sx={{
                    marginTop: '16px',
                    padding: '12px',
                    borderRadius: '4px',
                    backgroundColor: responseStatus === 1 ? '#e8f5e9' : '#ffebee',
                    color: responseStatus === 1 ? '#2e7d32' : '#c62828',
                }}>
                    {apiResponseText}
                </Box>
            )}
        </Box>);
};

export default CreateFileForm;