import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";

HC_more(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);

export default function TreemapChart({chartData, handlePointClick}) {
    const options = {
        plotOptions: {
            treemap: {
                colorByPoint: true,
                dataLabels: {
                    formatter: function (data) {
                        if (this.point.parent != (this.series.rootNode || null)) {
                            return this.key;
                        }
                    },
                },
            },
        },
        series: [
            {
                type: "treemap",
                layoutAlgorithm: "squarified",
                alternateStartingDirection: true,
                borderColor: "#fff",
                borderRadius: 6,
                borderWidth: 2,
                dataLabels: {
                    style: {
                        textOutline: "none",
                    },
                },
                levels: [
                    {
                        level: 1,
                        layoutAlgorithm: "sliceAndDice",
                        dataLabels: {
                            enabled: true,
                            align: "left",
                            verticalAlign: "top",
                            style: {
                                fontSize: "15px",
                                fontWeight: "bold",
                            },
                        },
                    },
                ],
                data: chartData,
                point: {
                    events: {
                        click: function (event) {
                            handlePointClick(event.point.name);
                        },
                    },
                },
            },
        ],
        title: {
            text: "Topics",
            align: "left",
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        tooltip: {
            useHTML: true,
            shared: false,
            formatter: function (tooltip) {
                var sentiment = 0
                if (this.point.sentiment) {
                    sentiment = this.point.sentiment.toFixed(2)
                }
                const bgColor = sentiment > 0 ? 'bg-green-700' : (sentiment < 0) ? 'bg-red:700' : 'bg-gray-500'
                const sentimentCss = `w-[4px] h-full rounded mr-2 ${bgColor}`;
                return `
                    <div class="flex w-full h-[50px] p-1 rounded-md bg-white">
                       <div class="${sentimentCss}"></div>
                        <div class="flex flex-col">
                            <div class="text-[16px] font-bold">${this.point.name}</div>
                            <div class="text-[12px] font-medium">Volume: ${this.point.volume}</div>
                        </div>
                    </div>
                `;
            }
        },
        credits: {
            enabled: false,
        },
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{
                style: {
                    width: "100%",  // Take full width of the parent container
                    height: "100%", // Take full height of the parent container
                    overflow: "hidden",  // Prevent overflow
                },
            }}
        />
    );
}
